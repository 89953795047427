import { Injectable, inject } from '@angular/core';
import {
  addDoc,
  collection,
  doc,
  Firestore,
  getDoc,
  orderBy,
  query,
  updateDoc,
  writeBatch,
  deleteDoc,
  where,
  getDocs,
  collectionData,
} from '@angular/fire/firestore';
import { Observable, Subscription } from 'rxjs';

import { Category, Product } from '@pedix-workspace/utils';
import { FileStorageService } from '@pedix-workspace/pedixapp-core-services';
import { CategoriesStoreService } from './categories-store.service';
import { getCategoryConverter } from '@pedix-workspace/shared-models';
import { EstablishmentService } from '../establishment.service';

type CategorySortInput = { id: Category['id']; sortOrder: Category['sortOrder'] };

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  private firestore = inject(Firestore);
  private fileStorage = inject(FileStorageService);
  private establishmentService = inject(EstablishmentService);
  private categoriesStoreService = inject(CategoriesStoreService);

  private categoriesWatchSubscription: Subscription;

  get categoriesConverter() {
    return getCategoryConverter({
      features: this.establishmentService.currentEstablishment.features,
    });
  }

  get categoryCollection() {
    return collection(this.firestore, 'categories').withConverter(this.categoriesConverter);
  }

  watchForCategoryUpdates(establishmentId: string) {
    if (this.categoriesWatchSubscription) {
      this.categoriesWatchSubscription.unsubscribe();
    }

    const q = query(
      this.categoryCollection,
      where('establishmentId', '==', establishmentId),
      where('updated', '>', new Date()),
    );

    this.categoriesWatchSubscription = collectionData(q, { idField: 'id' }).subscribe(
      categories => {
        this.categoriesStoreService.addCategoriesToCache(establishmentId, <Category[]>categories, {
          updateCache: false,
        });
      },
    );
  }

  getCategories(establishmentId: string): Observable<Category[]> {
    if (!this.categoriesStoreService.hasResultsForEstablishmentId(establishmentId)) {
      this.fetchCategoriesByEstablishmentId(establishmentId).then(categories =>
        this.categoriesStoreService.addCategoriesToCache(establishmentId, categories, {
          updateCache: true,
        }),
      );
    }
    return this.categoriesStoreService.getCategoriesByEstablishment$(establishmentId);
  }

  getCategory(categoryId: string): Observable<Category | undefined> {
    if (!this.categoriesStoreService.hasResultsForCategoryId(categoryId)) {
      this.fetchCategoryById(categoryId).then(category =>
        this.categoriesStoreService.addCategoryToCache(categoryId, category),
      );
    }

    return this.categoriesStoreService.getCategoryById$(categoryId);
  }

  getCategoryByNameSync(establishmentId: string, categoryName: string): Category | undefined {
    return this.categoriesStoreService
      .getCategoriesByEstablishmentSync(establishmentId)
      .find(category => category.name === categoryName);
  }

  fetchCategoriesByEstablishmentId(establishmentId: string): Promise<Category[]> {
    const q = query(
      this.categoryCollection,
      where('establishmentId', '==', establishmentId),
      orderBy('sortOrder', 'asc'),
    );

    return getDocs(q).then(categoriesQs => {
      if (categoriesQs.empty) {
        return [];
      }
      return categoriesQs.docs.map(categoryDoc => categoryDoc.data());
    });
  }

  fetchCategoryById(categoryId: string): Promise<Category | undefined> {
    const docRef = doc(this.categoryCollection, categoryId);

    return getDoc(docRef).then(categoryDoc => {
      const category = categoryDoc.exists() ? categoryDoc.data() : undefined;

      return category;
    });
  }

  async addCategory(category: Category, oldCategory?: Category): Promise<Category> {
    const isNewCategory = !category.id;

    if (isNewCategory && category.sortOrder === undefined) {
      category.added = new Date();
      category.sortOrder = this.getNextCategorySortIndex(category.establishmentId);
    }
    category.updated = new Date();

    const categoryData = { ...category };

    if (category.image !== oldCategory?.image) {
      if (categoryData.image) {
        categoryData.image = await this.uploadCategoryImage(categoryData);
      }
    }

    let savedCategory: Category;

    if (isNewCategory) {
      const docRef = await addDoc(this.categoryCollection, { ...categoryData });

      savedCategory = { ...categoryData, id: docRef.id };
    } else {
      await updateDoc(doc(this.categoryCollection, category.id), { ...categoryData });

      savedCategory = categoryData;
    }

    this.categoriesStoreService.addCategoryToCache(savedCategory.id, savedCategory);

    return savedCategory;
  }

  async updateCategory(categoryId: string, categoryData: Partial<Category>): Promise<void> {
    await updateDoc(doc(this.categoryCollection, categoryId), {
      ...categoryData,
      updated: new Date(),
    });

    this.categoriesStoreService.patchCategoryInCache(categoryId, categoryData);
  }

  async removeCategory(category: Pick<Category, 'id'>): Promise<void> {
    await deleteDoc(doc(this.categoryCollection, category.id));

    this.categoriesStoreService.removeCategoryFromCache(category.id);
  }

  saveCategoriesOrder(categories: CategorySortInput[]) {
    const batch = writeBatch(this.firestore);

    categories.forEach((category, index) => {
      const hasChanged = category.sortOrder !== index;

      if (hasChanged) {
        const categoryRef = doc(this.categoryCollection, category.id);
        const patchData = { sortOrder: index };

        batch.update(categoryRef, patchData);

        this.categoriesStoreService.patchCategoryInCache(category.id, patchData);
      }
    });

    return batch.commit();
  }

  getNextCategorySortIndex(establishmentId: string) {
    const categories =
      this.categoriesStoreService.getCategoriesByEstablishmentSync(establishmentId);
    const lastCategory = categories[categories.length - 1];

    return lastCategory ? lastCategory.sortOrder + 1 : 1;
  }

  private async uploadCategoryImage(category: Category, fileName = Date.now()): Promise<string> {
    const filePath = `${this.establishmentService.currentEstablishment.id}/categories/${fileName}`;

    return this.fileStorage.uploadBase64File(filePath, category.image || '');
  }

  async getActiveCategoriesWithProducts(): Promise<Category[]> {
    const categoriesQuery = query(
      this.categoryCollection,
      where('active', '==', true),
      where('establishmentId', '==', this.establishmentService.currentEstablishment.id),
    );
    const categoriesSnapshot = await getDocs(categoriesQuery);

    if (categoriesSnapshot.empty) {
      return [];
    }

    const categoriesWithProducts: Category[] = [];

    for (const categoryDoc of categoriesSnapshot.docs) {
      const category = categoryDoc.data();
      const productsQuery = query(
        collection(this.firestore, 'products'),
        where('categoryId', '==', category.id),
      );
      const productsSnapshot = await getDocs(productsQuery);

      if (!productsSnapshot.empty) {
        const productsWithImages = productsSnapshot.docs.filter(productDoc => {
          const product = productDoc.data() as Product;
          return product.images?.length > 0;
        });

        if (productsWithImages.length > 0) {
          categoriesWithProducts.push(category);
        }
      }
    }

    return categoriesWithProducts;
  }
}
