import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  Firestore,
  where,
  orderBy,
  query,
  updateDoc,
  writeBatch,
  getDoc,
  getDocs,
  collectionData,
} from '@angular/fire/firestore';
import {
  FileStorageService,
  FileUploadOptions,
  UploadFileRef,
} from '@pedix-workspace/pedixapp-core-services';
import {
  Product,
  ProductOptionItem,
  getImageToClone,
  generateRandomFileName,
  SUPPORTED_LANGUAGES,
  objectShallowDiffWithDeepCompare,
} from '@pedix-workspace/utils';
import { EMPTY, forkJoin, from, merge, Observable, of, Subject, Subscription } from 'rxjs';
import { map, filter, delay, switchMap, tap } from 'rxjs/operators';
import { isPlatformServer } from '@angular/common';
import { nanoid } from 'nanoid';
import { getProductConverter, sortProductsByStock } from '@pedix-workspace/shared-models';
import { EstablishmentService } from '../establishment.service';
import { limit, QueryConstraint } from '@angular/fire/firestore';
import { TranslocoService } from '@ngneat/transloco';
import { ProductsStoreService } from './products-store.service';

const SERVER_PRODUCTS_LIMIT = 30;

type ProductSortInput = { id: Product['id']; sortOrder: Product['sortOrder'] };

export type SaveProductParams = {
  product: Product;
  originalProduct: Product;
  storageBucket: string;
  saveAsCopy?: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  productCreated$ = new Subject<Product>();
  productUpdated$ = new Subject<Product>();

  get store() {
    return this.productsStoreService;
  }

  private platformId = inject(PLATFORM_ID);
  private firestore = inject(Firestore);
  private fileStorage = inject(FileStorageService);
  private establishmentService = inject(EstablishmentService);
  private t = inject(TranslocoService);
  private productsStoreService = inject(ProductsStoreService);

  private productsWatchSubscription: Subscription;

  constructor() {
    // For products that are processing images in the background, we schedule a fetch to update product status
    merge(this.productCreated$, this.productUpdated$)
      .pipe(
        filter(product => {
          return !!product && this.productIsUpdatingImages(product);
        }),
        switchMap(product => {
          if (!product) {
            return EMPTY;
          }
          return of(product).pipe(
            delay(5000),
            switchMap(() => this.fetchProductById(product.id)),
          );
        }),
      )
      .subscribe(product => {
        if (product) {
          this.productsStoreService.addProductToCache(product.id, product);
        }
      });
  }

  get productConverter() {
    return getProductConverter({
      nestedConverter: {
        fromFirestore: product => {
          if (product.presentations) {
            product.presentations.name = this.t.translate('product.presentationsLabel');
          }
          return product;
        },
      },
      features: this.establishmentService.currentEstablishment.features,
    });
  }

  get productCollection() {
    return collection(this.firestore, 'products').withConverter(this.productConverter);
  }

  watchForProductUpdates(establishmentId: string) {
    if (this.productsWatchSubscription) {
      this.productsWatchSubscription.unsubscribe();
    }

    const q = query(
      this.productCollection,
      where('establishmentId', '==', establishmentId),
      where('updated', '>', new Date()),
    );

    this.productsWatchSubscription = collectionData(q, { idField: 'id' }).subscribe(products => {
      this.productsStoreService.addProductsToCache(<Product[]>products);
    });
  }

  getProductsByCategory(categoryId: string): Observable<Product[]> {
    if (!this.productsStoreService.hasResultsForCategoryId(categoryId)) {
      this.fetchProductsByCategoryId(categoryId).then(products =>
        this.productsStoreService.addCategoryProductsToCache(categoryId, products),
      );
    }
    return this.productsStoreService.getProductsByCategory$(categoryId);
  }

  getProductsByLinkedSharedOption(sharedOptionId: string): Observable<Product[]> {
    if (!this.productsStoreService.hasResultsForSharedOptionId(sharedOptionId)) {
      this.fetchProductsByLinkedSharedOptionId(sharedOptionId).then(products =>
        this.productsStoreService.addSharedOptionProductsToCache(sharedOptionId, products),
      );
    }
    return this.productsStoreService.getProductsByLinkedSharedOption$(sharedOptionId);
  }

  getProductsByLinkedProductTags(productTagId: string): Observable<Product[]> {
    if (!this.productsStoreService.hasResultsForProductTagId(productTagId)) {
      this.fetchProductsByLinkedProductTagId(productTagId).then(products =>
        this.productsStoreService.addProductTagProductsToCache(productTagId, products),
      );
    }
    return this.productsStoreService.getProductsByLinkedProductTags$(productTagId);
  }

  getProductListItemsByCategory(
    categoryId: string,
    options: {
      filterHiddenProducts: boolean;
      sortProductsByStock: boolean;
      selectedLanguage?: SUPPORTED_LANGUAGES;
    },
  ): Observable<Product[] | undefined> {
    return this.getProductsByCategory(categoryId).pipe(
      map(products => {
        if (options.filterHiddenProducts) {
          return products.filter(product => !product.hidden);
        }
        return products;
      }),
      map(products => {
        if (options.sortProductsByStock) {
          return products.sort(sortProductsByStock());
        }
        return products;
      }),
      map(products =>
        products.map((product, listIndex) => {
          const price = product.presentations
            ? this.getPresentationMinimumPrice(product.presentations)
            : product.price;

          return {
            ...product,
            searchableName: product.name.toLowerCase(),
            isUpdatingImages: this.productIsUpdatingImages(product),
            listIndex,
            price,
          };
        }),
      ),
    );
  }

  productIsUpdatingImages(
    productData: Pick<Product, 'images' | 'imagesToClone' | 'updated'>,
  ): boolean {
    if (
      Array.isArray(productData.images) &&
      productData.images.some(image => image.includes('temp-uploads'))
    ) {
      return true;
    }
    if (Array.isArray(productData.imagesToClone) && productData.imagesToClone.length > 0) {
      return true;
    }
    return false;
  }

  getProduct(productId: string): Observable<Product | undefined> {
    if (!this.productsStoreService.hasResultsForProductId(productId)) {
      this.fetchProductById(productId).then(product =>
        this.productsStoreService.addProductToCache(productId, product),
      );
    }

    return this.productsStoreService.getProductById$(productId);
  }

  fetchProductById(productId: string): Promise<Product | undefined> {
    const docRef = doc(this.productCollection, productId);

    return getDoc(docRef).then(productDoc => productDoc.data());
  }

  fetchProductsByCategoryId(categoryId: string): Promise<Product[]> {
    const queryConstrains: QueryConstraint[] = [];

    queryConstrains.push(where('categoryId', '==', categoryId));
    queryConstrains.push(orderBy('sortOrder', 'asc'));

    if (isPlatformServer(this.platformId)) {
      queryConstrains.push(limit(SERVER_PRODUCTS_LIMIT));
    }

    const productsQuery = query(this.productCollection, ...queryConstrains);

    return getDocs(productsQuery).then(productsQs => {
      if (productsQs.empty) {
        return [];
      }
      return productsQs.docs.map(product => product.data());
    });
  }

  fetchProductsByLinkedSharedOptionId(sharedOptionId: string): Promise<Product[]> {
    const queryConstrains: QueryConstraint[] = [];

    queryConstrains.push(where('linkedOptionIds', 'array-contains', sharedOptionId));

    const productsQuery = query(this.productCollection, ...queryConstrains);

    return getDocs(productsQuery).then(productsQs => {
      if (productsQs.empty) {
        return [];
      }
      return productsQs.docs.map(product => product.data());
    });
  }

  fetchProductsByLinkedProductTagId(productTagId: string): Promise<Product[]> {
    const queryConstrains: QueryConstraint[] = [];

    queryConstrains.push(where('linkedProductTagIds', 'array-contains', productTagId));

    const productsQuery = query(this.productCollection, ...queryConstrains);

    return getDocs(productsQuery).then(productsQs => {
      if (productsQs.empty) {
        return [];
      }
      return productsQs.docs.map(product => product.data());
    });
  }

  async saveProduct({ product, saveAsCopy, storageBucket, originalProduct }: SaveProductParams) {
    const isNew = !product.id;
    let savedProduct: Product;

    if (isNew) {
      savedProduct = await this.addProduct(product);
    } else if (saveAsCopy) {
      savedProduct = await this.cloneProduct(product, originalProduct, storageBucket);
    } else {
      savedProduct = await this.updateProduct(product, originalProduct);
    }

    this.productsStoreService.addProductToCache(savedProduct.id, savedProduct);

    // This needs to happen AFTER updating the cache
    if (savedProduct.id === product.id) {
      this.productUpdated$.next(savedProduct);
    } else {
      this.productCreated$.next(savedProduct);
    }

    return savedProduct;
  }

  private async addProduct(product: Product): Promise<Product> {
    // Manually use productConverter.toFirestore(), as it's not being triggered on Firebase v9
    const productData = this.productConverter.toFirestore({
      ...product,
      added: new Date(),
      updated: new Date(),
      sortOrder: this.getNextProductSortIndex(product.categoryId),
      establishmentId: this.establishmentService.currentEstablishment.id,
    });

    const docRef = await addDoc(this.productCollection, productData);

    return <Product>{ ...productData, id: docRef.id };
  }

  private async cloneProduct(
    product: Product,
    originalProduct: Product,
    storageBucket: string,
  ): Promise<Product> {
    const productCopy: Product = {
      ...product,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      sortOrder: undefined as any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      id: undefined as any,
      clonedFrom: undefined,
    };

    if (originalProduct.name === productCopy.name) {
      productCopy.name += ' ' + this.t.translate('global.copySuffix');
    }

    if (productCopy.images) {
      productCopy.imagesToClone = productCopy.images.map(image => {
        return getImageToClone(image, storageBucket, generateRandomFileName());
      });
      productCopy.images = [];
    }

    return this.addProduct(productCopy);
  }

  private async updateProduct(product: Product, originalProduct: Product): Promise<Product> {
    const productData = <Product>this.productConverter.toFirestore(product);

    productData.updated = new Date();

    if (productData.sortOrder === undefined) {
      productData.sortOrder = this.getNextProductSortIndex(productData.categoryId);
    }

    // Manually use productConverter.toFirestore(), as it's not being triggered on Firebase v9
    const productDiff = objectShallowDiffWithDeepCompare(originalProduct, productData);

    await updateDoc(doc(this.productCollection, product.id), productDiff);

    return {
      ...product,
      ...productData,
    };
  }

  async removeProduct(productId: Product['id']): Promise<void> {
    await deleteDoc(doc(this.productCollection, productId));

    this.productsStoreService.removeProductFromCache(productId);
  }

  createEmptyProductOptionItem(): ProductOptionItem {
    return {
      id: nanoid(),
      isOptionItem: false, // This is false, cause true would be only for Shared option-items
      name: '',
      price: 0,
      stockQty: null,
      description: '',
      maxSelection: undefined,
    };
  }

  saveProductsOrder(products: ProductSortInput[]) {
    const batch = writeBatch(this.firestore);

    products.forEach((product, index) => {
      const hasChanged = product.sortOrder !== index;

      if (hasChanged) {
        const productRef = doc(this.productCollection, product.id);
        const patchData = { sortOrder: index };

        batch.update(productRef, patchData);

        this.productsStoreService.patchProductInCache(product.id, patchData);
      }
    });

    return batch.commit();
  }

  async patchProduct(productId: string, patchData: Partial<Product>) {
    const productRef = doc(this.productCollection, productId);

    await updateDoc(productRef, patchData);

    this.productsStoreService.patchProductInCache(productId, patchData);
  }

  batchPatchProducts(productPatches: ({ id: string } & Partial<Product>)[]) {
    const batch = writeBatch(this.firestore);

    productPatches.forEach(patchData => {
      const productRef = doc(this.productCollection, patchData.id);

      batch.update(productRef, patchData);

      this.productsStoreService.patchProductInCache(patchData.id, patchData);
    });

    return batch.commit();
  }

  batchDeleteProducts(productIds: string[]) {
    const batch = writeBatch(this.firestore);

    productIds.forEach(productId => {
      const productRef = doc(this.productCollection, productId);

      batch.delete(productRef);

      this.productsStoreService.removeProductFromCache(productId);
    });

    return batch.commit();
  }

  getNextProductSortIndex(categoryId: string) {
    const products = this.productsStoreService.getProductsByCategorySync(categoryId);
    const lastProduct = products[products.length - 1];

    return lastProduct ? lastProduct.sortOrder + 1 : 1;
  }

  async getBase64ImageFromUrl(imageUrl: string): Promise<string> {
    const res = await fetch(imageUrl);
    const blob = await res.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(`${reader.result}`), false);

      reader.onerror = () => reject();
      reader.readAsDataURL(blob);
    });
  }

  getGroupsForCategory(categoryId: string): string[] {
    const products = this.productsStoreService.getProductsByCategorySync(categoryId);
    const uniqueGroups = new Set<string>();

    products?.forEach(product => {
      if (product.groupName) {
        uniqueGroups.add(product.groupName);
      }
    });
    return Array.from(uniqueGroups);
  }

  createEmptyProduct(categoryId: string, options: { isOptionItem?: boolean } = {}): Product {
    const product = {
      added: new Date(),
      updated: new Date(),
      images: [],
      linkedProductTagIds: [],
      sortOrder: 0,
      categoryId,
      stockQty: null,
      hidden: false,
    } as unknown as Product;

    if (options.isOptionItem) {
      product.isOptionItem = true;
    }

    return product;
  }

  sortProductsByGroupAndName<T extends Product | Product>(products: T[]): T[] {
    return products.sort((a, b) => {
      if (a.groupName === b.groupName) {
        return a.name < b.name ? -1 : 1;
      }
      if (!a.groupName) {
        return 1;
      }
      if (!b.groupName) {
        return -1;
      }
      return a.groupName < b.groupName ? -1 : 1;
    });
  }

  async uploadProductImage(
    imageFile: Blob,
    fileName?: string,
    options: FileUploadOptions = {},
  ): Promise<string> {
    const filePath = `${this.establishmentService.currentEstablishment.id}/products/${fileName || nanoid()}`;

    return this.fileStorage.uploadFile(filePath, imageFile, options);
  }

  async batchUploadProductImages(
    blobs: Blob[],
    options: FileUploadOptions = {},
  ): Promise<(string | Error)[]> {
    const filesToUpload: UploadFileRef[] = blobs.map(blob => ({
      file: blob,
      filePath: `${this.establishmentService.currentEstablishment.id}/products/${nanoid()}`,
    }));
    return this.fileStorage.batchUploadFiles(filesToUpload, options);
  }

  fetchProductsByIds(
    productIds: string[],
    options: { ignoreCache?: boolean } = {},
  ): Observable<(Product | undefined)[]> {
    return forkJoin(
      productIds.map(productId => {
        if (!options.ignoreCache && this.store.hasResultsForProductId(productId)) {
          return this.store.getProductById$(productId);
        }
        return from(this.fetchProductById(productId));
      }),
    ).pipe(tap(products => this.store.addProductsToCache(products)));
  }

  private getPresentationMinimumPrice(presentations: Product['presentations']): number {
    return (
      presentations?.items?.reduce(
        (minimumPrice, presentation) =>
          presentation.price < minimumPrice ? presentation.price : minimumPrice,
        Number.MAX_VALUE,
      ) || 0
    );
  }
}
