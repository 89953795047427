import { Injectable, inject } from '@angular/core';
import { InvitedUserRole, Feature, Country } from '@pedix-workspace/utils';
import { EstablishmentService } from './features/catalog/establishment.service';
import { AppUserService } from './app-user.service';

export type FeaturesAndRolesParams = {
  features?: Feature[];
  anyFeatures?: Feature[];
  roles?: InvitedUserRole[];
  anyRoles?: InvitedUserRole[];
  anyCountryCodes?: Country[];
  condition?: boolean;
};

export type AllowedFeatures = {
  catalog: boolean;
  orders: boolean;
  statistics: boolean;

  generalInfo: boolean;
  workingHours: boolean;
  theme: boolean;
  announcements: boolean;

  paymentMethods: boolean;
  shippingOptions: boolean;
  customFields: boolean;

  seoSocialMedia: boolean;
  analytics: boolean;
  advancedAnalytics: boolean;

  integrations: boolean;

  ads: boolean;
  qrCode: boolean;

  coupons: boolean;

  userManagement: boolean;
  subscriptionManagement: boolean;

  sku: boolean;
  productTags: boolean;
  bulkEdit: boolean;
};
export type AllowedFeature = keyof AllowedFeatures;

@Injectable({
  providedIn: 'root',
})
export class AppFeaturesAndRolesService {
  private establishmentService = inject(EstablishmentService);
  private userService = inject(AppUserService);

  get establishmentFeatures(): Feature[] {
    return this.establishmentService.currentEstablishment?.features || [];
  }
  get establishmentCountryCode(): Country {
    return this.establishmentService.currentEstablishment.countryCode;
  }

  test(params: FeaturesAndRolesParams) {
    const userRoles = this.userService.userRoles;

    if (params.features) {
      if (!params.features.every(feature => this.establishmentFeatures.includes(feature))) {
        return false;
      }
    }
    if (params.anyFeatures) {
      if (!params.anyFeatures.some(feature => this.establishmentFeatures.includes(feature))) {
        return false;
      }
    }
    if (userRoles.includes('ESTABLISHMENT_ADMIN')) {
      return true;
    }
    if (params.roles) {
      if (!params.roles.every(role => userRoles.includes(role))) {
        return false;
      }
    }
    if (params.anyRoles) {
      if (!params.anyRoles.some(role => userRoles.includes(role))) {
        return false;
      }
    }
    if (params.anyCountryCodes) {
      if (!params.anyCountryCodes.includes(this.establishmentCountryCode)) {
        return false;
      }
    }
    if (params.condition === false) {
      return false;
    }
    return true;
  }

  getAllowedFeatures(): AllowedFeatures {
    return {
      catalog: this.test({ roles: ['CATALOG_ADMIN'] }),
      orders: this.test({ roles: ['ORDERS_ADMIN'] }),
      statistics: this.test({
        roles: ['STATISTICS_ADMIN'],
      }),

      generalInfo: this.test({
        roles: ['GENERAL_INFO_ADMIN'],
      }),
      workingHours: this.test({
        features: ['WORKING_HOURS'],
        roles: ['GENERAL_INFO_ADMIN'],
      }),
      theme: this.test({
        features: ['THEME'],
        roles: ['GENERAL_INFO_ADMIN'],
      }),
      announcements: this.test({
        features: ['ANNOUNCEMENTS'],
        roles: ['GENERAL_INFO_ADMIN'],
      }),

      paymentMethods: this.test({
        roles: ['PAYMENT_ADMIN'],
      }),
      shippingOptions: this.test({
        roles: ['SHIPPING_ADMIN'],
      }),
      customFields: this.test({
        features: ['CHECKOUT_CUSTOM_FIELDS'],
        roles: ['CUSTOM_FIELDS_ADMIN'],
      }),

      seoSocialMedia: this.test({
        features: ['SEO_SOCIAL_MEDIA'],
        roles: ['GENERAL_INFO_ADMIN'],
      }),
      analytics: this.test({
        anyFeatures: ['GOOGLE_ANALYTICS', 'GOOGLE_TAG_MANAGER', 'FACEBOOK_PIXEL'],
        roles: ['ANALYTICS_ADMIN'],
      }),
      advancedAnalytics: this.test({
        features: ['GOOGLE_TAG_MANAGER'],
        roles: ['ANALYTICS_ADMIN'],
      }),

      integrations: this.test({
        features: ['INTEGRATIONS'],
        roles: ['INTEGRATIONS_ADMIN'],
      }),

      ads: this.test({
        features: ['ADS'],
        roles: ['ADS_ADMIN'],
      }),
      qrCode: this.test({
        features: ['QR_CODE'],
        roles: ['GENERAL_INFO_ADMIN'],
      }),

      coupons: this.test({
        features: ['COUPONS'],
        roles: ['COUPONS_ADMIN'],
      }),

      userManagement: this.test({
        features: ['USER_MANAGEMENT'],
        roles: ['ESTABLISHMENT_ADMIN'],
      }),
      subscriptionManagement: this.test({
        roles: ['ESTABLISHMENT_ADMIN'],
      }),
      sku: this.test({
        features: ['SKU'],
        roles: ['CATALOG_ADMIN'],
      }),
      productTags: this.test({
        features: ['PRODUCT_TAGS'],
        roles: ['CATALOG_ADMIN'],
      }),
      bulkEdit: this.test({
        features: ['BULK_EDIT'],
        roles: ['CATALOG_ADMIN'],
      }),
    };
  }
}
