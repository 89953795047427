import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  booleanAttribute,
  effect,
  inject,
  signal,
} from '@angular/core';
import { NgClass } from '@angular/common';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import clsx from 'clsx';
import { IconInfoComponent } from '@pedix-workspace/angular-ui-icons';
import {
  WithDefaultValidatorProps,
  composeValidatorFn,
  hasChangedAnyDefaultValidatorProps,
} from '../utils/defaultValidator';
import { UiFormReactiveConfigService } from '../form-reactive-config.service';
import { SelectBoxComponent } from '../select-box/select-box.component';
import { COUNTRY_CODE_OTHER, COUNTRY_LIST } from './input-phone.constants';
import { CountryCode, isValidNumber, parse } from 'libphonenumber-js';

let INPUT_UID = 0;

export type InputPhoneLabelSize = 'xs' | 'sm' | 'md';

export type InputPhoneValueType = string | undefined | null;

export interface InputPhoneCountry {
  code: string;
  flag: string;
  name: string;
  countryPrefix: string;
  flagImage: string;
}

@Component({
  selector: 'pxw-input-phone',
  standalone: true,
  templateUrl: './input-phone.component.html',
  styleUrl: './input-phone.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputPhoneComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: InputPhoneComponent,
    },
  ],
  imports: [NgClass, IconInfoComponent, SelectBoxComponent, FormsModule],
})
export class InputPhoneComponent
  implements OnInit, OnChanges, ControlValueAccessor, Validator, WithDefaultValidatorProps
{
  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  @Input({ required: true }) name: string;
  @Input() type: 'text' | 'password';
  @Input() label: string;
  @Input() labelSize: InputPhoneLabelSize = 'sm';
  @Input() helper: string;
  @Input() placeholder = '';
  @Input() autofocus: boolean;
  @Input() messages: { [key: string]: string } = {};
  @Input() displayErrors = false;

  @Input() disabledCountryCode = false;
  @Input() countrySelected: InputPhoneCountry | null = null;
  @Input() initCountryCode: CountryCode | 'XX';

  @Input({ transform: booleanAttribute }) required?: boolean;
  @Input({ transform: booleanAttribute }) requiredTrue?: boolean;
  @Input({ transform: booleanAttribute }) email?: boolean;
  @Input() minLength?: number;
  @Input() maxLength?: number;
  @Input() pattern?: string | RegExp;
  @Input() custom?: ValidatorFn;

  @Output() keyUp = new EventEmitter<KeyboardEvent>();
  @Output() focusOut = new EventEmitter<void>();

  private injector = inject(Injector);
  private isFirstRun = true;

  protected inputId: string;
  protected isDisabled: boolean;

  #value = signal<InputPhoneValueType>(undefined);
  #displayCountryDropdown = signal(true);
  #countries = signal<InputPhoneCountry[]>(COUNTRY_LIST);
  #countryCode = signal<string | null>(this.countries[0].code);
  #phoneNumber = signal<string | null>(null);

  private onChange: (value: InputPhoneValueType) => void;
  private onTouched: () => void;
  private onValidatorChange: () => void;
  private validatorFn: ValidatorFn | null;
  private formReactiveConfigService = inject(UiFormReactiveConfigService);

  @HostBinding('class') get classNames() {
    return clsx('ui-input', `ui-input-text`, {
      'ui-input--disabled': this.isDisabled,
      'ui-input--with-helper': this.helper,
      'ui-input--with-errors': !this.isValid,
    });
  }

  get value(): InputPhoneValueType {
    return this.#value();
  }
  get formControl() {
    const ngControl = this.injector.get(NgControl, null);

    if (ngControl) {
      return ngControl.control as FormControl;
    }
    return null;
  }
  get isRequired() {
    return this.formControl?.hasValidator(Validators.required) || this.required === true;
  }
  get isValid() {
    return this.formControl?.valid;
  }
  get isTouched() {
    return this.formControl?.touched;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get errorEntries(): [string, any][] {
    return Object.entries(this.formControl?.errors || {});
  }
  get shouldDisplayErrors() {
    return (this.displayErrors || this.isTouched) && !this.isValid && !this.isDisabled;
  }
  get displayCountryDropdown() {
    return this.#displayCountryDropdown();
  }
  get countries() {
    return this.#countries();
  }
  get phoneNumber() {
    return this.#phoneNumber();
  }
  get countryCode() {
    return this.#countryCode();
  }

  constructor() {
    effect(
      () => {
        this.onFullNumberChanged(this.countryCode, this.phoneNumber);
      },
      {
        allowSignalWrites: true,
      },
    );
  }

  ngOnInit(): void {
    this.inputId = `input-text-${++INPUT_UID}`;

    this.validatorFn = composeValidatorFn(this, [this.phoneNumberValidator]);

    setTimeout(() => {
      this.isFirstRun = false;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (hasChangedAnyDefaultValidatorProps(changes)) {
      this.validatorFn = composeValidatorFn(this, [this.phoneNumberValidator]);

      if (this.formControl) {
        this.formControl.updateValueAndValidity();
      }
    }
  }

  writeValue(value: InputPhoneValueType) {
    if (this.isFirstRun) {
      this.initializeInputValues(value);
    }

    this.#value.set(value || null);
  }
  registerOnChange(onChange: (value: InputPhoneValueType) => void) {
    this.onChange = onChange;
  }
  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }
  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validate(control: AbstractControl): ValidationErrors | null {
    return this.validatorFn?.(control) || null;
  }
  registerOnValidatorChange(onValidatorChange: () => void): void {
    this.onValidatorChange = onValidatorChange;
  }

  onKeyUp($event: KeyboardEvent) {
    const newValue = (<HTMLInputElement>$event.target).value;

    this.#phoneNumber.set(newValue);

    this.keyUp.emit($event);
  }

  focus() {
    this.input.nativeElement.focus();
  }

  onFocusOut(event: Event) {
    this.focusOut.emit();

    const target = <HTMLInputElement>event.target;

    if (target.value && target.value !== target.value.replace(/\D/g, '')) {
      const newValue = target.value.replace(/\D/g, '');

      this.#phoneNumber.set(newValue);
    }
  }

  onPaste($event: ClipboardEvent) {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    const target = <HTMLInputElement>$event.target;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const pastedText = ($event.clipboardData || (window as any)['clipboardData']).getData('text');
    const start = target.selectionStart || 0;
    const end = target.selectionEnd || 0;
    const currentValue = target.value;

    const newValue = currentValue.slice(0, start) + pastedText + currentValue.slice(end);

    this.#phoneNumber.set(newValue);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected getErrorMessage([key, data]: [string, any]) {
    return this.messages?.[key] || this.formReactiveConfigService.getErrorMessage(key, data);
  }

  onSelectedCountry(value: string | null) {
    this.#countryCode.set(value);
  }

  onFullNumberChanged(countryCode: string | null, phoneNumber: string | null) {
    const sanitizedPhoneNumber = (phoneNumber || '').replace(/\D/g, '');
    const selectedCountry = countryCode
      ? this.countries.find(country => country.code === this.countryCode)
      : null;

    const fullNumber =
      selectedCountry && sanitizedPhoneNumber
        ? `${selectedCountry.countryPrefix}${sanitizedPhoneNumber}`
        : null;

    if (fullNumber !== this.value) {
      this.writeValue(fullNumber);
      this.onChange(fullNumber);
      this.onValidatorChange();
      this.onTouched();
    }
  }

  private phoneNumberValidator: ValidatorFn = control => {
    const phoneNumber = control.value;

    if (!phoneNumber) {
      return null;
    }
    if (!this.countryCode || this.countryCode === COUNTRY_CODE_OTHER) {
      const phoneNumberLength = (phoneNumber || '').length;

      if (phoneNumberLength >= 11 && phoneNumberLength <= 15) {
        return null;
      } else {
        return { invalidPhone: true };
      }
    }
    try {
      const numberObject = parse(phoneNumber, this.countryCode as CountryCode);

      if (isValidNumber(numberObject)) {
        return null;
      } else {
        return { invalidPhone: true };
      }
    } catch (e) {
      return { invalidPhone: true };
    }
  };

  private initializeInputValues(value?: string | null) {
    if (value) {
      const matchedCountry = this.countries.find(country =>
        value.startsWith(country.countryPrefix),
      );
      const countryPrefix = matchedCountry ? matchedCountry.countryPrefix : '';

      this.#countryCode.set(matchedCountry ? matchedCountry.code : null);
      this.#phoneNumber.set(matchedCountry ? value.substr(countryPrefix.length) : value);
    } else if (this.initCountryCode) {
      this.#countryCode.set(this.initCountryCode);
    }
  }
}
