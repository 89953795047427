import { Injectable, inject, signal } from '@angular/core';
import {
  Firestore,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  collection,
  query,
  where,
  collectionData,
  setDoc,
  deleteField,
} from '@angular/fire/firestore';
import { combineLatest, Observable, Subscription } from 'rxjs';
import {
  Establishment,
  PrivateConfiguration,
  reservedSlugWords,
  SubscriptionStatus,
  getCurrencyLocaleDataByCountryCode,
  AVAILABLE_LANGUAGES,
  DEFAULT_LANGUAGE,
} from '@pedix-workspace/utils';
import { map } from 'rxjs/operators';
import { FileStorageService } from '@pedix-workspace/pedixapp-core-services';
import { getEstablishmentConverter } from '@pedix-workspace/shared-models';
import { toObservable } from '@angular/core/rxjs-interop';
import { Functions, httpsCallable } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root',
})
export class EstablishmentService {
  private firestore = inject(Firestore);
  private functions = inject(Functions);
  private fileStorage = inject(FileStorageService);

  private currentEstablishmentSubscription: Subscription;

  #currentEstablishment = signal<Establishment | null>(null);
  currentEstablishment$ = toObservable(this.#currentEstablishment);

  get currentEstablishment(): Establishment | null {
    return this.#currentEstablishment();
  }

  get currencyLocaleData() {
    return getCurrencyLocaleDataByCountryCode(this.currentEstablishment.currencyCode);
  }

  get availableLanguages(): AVAILABLE_LANGUAGES {
    if (
      !this.currentEstablishment.features.includes('ALT_LANGUAGE') ||
      !this.currentEstablishment.altLanguage
    ) {
      return [DEFAULT_LANGUAGE];
    }
    return [DEFAULT_LANGUAGE, this.currentEstablishment.altLanguage];
  }

  get establishmentsConverter() {
    return getEstablishmentConverter({
      nestedConverter: undefined,
    });
  }

  get establishmentCollection() {
    return collection(this.firestore, 'establishments').withConverter(this.establishmentsConverter);
  }

  get privateConfigurationPath() {
    return `establishments/${this.currentEstablishment.id}/private/configuration`;
  }

  async isSlugAvailable(slug: string) {
    if (reservedSlugWords.includes(slug)) {
      return false;
    }

    const q = query(this.establishmentCollection, where('slug', '==', slug));
    const results = await getDocs(q);

    return results.empty === true;
  }

  clearCurrentEstablishmentSubscription() {
    if (this.currentEstablishmentSubscription) {
      this.currentEstablishmentSubscription.unsubscribe();
    }
  }

  setCurrentEstablishmentAndWatchForChanges(establishment: Establishment | null) {
    this.clearCurrentEstablishmentSubscription();

    if (establishment) {
      this.currentEstablishmentSubscription = this.queryEstablishmentBySlug$(
        establishment.slug,
      ).subscribe(_establishment => {
        this.#currentEstablishment.set(_establishment);
      });
    }

    this.#currentEstablishment.set(establishment);
  }

  getEstablishmentByUser$(userId: string): Observable<Establishment | null> {
    const q = query(this.establishmentCollection, where('userId', '==', userId));

    return <Observable<Establishment | null>>(
      collectionData(q, { idField: 'id' }).pipe(
        map(queryResults => (queryResults.length > 0 ? queryResults[0] : null)),
      )
    );
  }

  queryEstablishmentBySlug$(slug: string): Observable<Establishment | null> {
    const q = query(this.establishmentCollection, where('slug', '==', slug));

    return <Observable<Establishment | null>>(
      collectionData(q, { idField: 'id' }).pipe(
        map(queryResults => (queryResults.length > 0 ? queryResults[0] : null)),
      )
    );
  }

  getEstablishmentById(establishmentId: string) {
    const docRef = doc(this.establishmentCollection, establishmentId);

    return getDoc(docRef).then(documentSnapshot => {
      if (!documentSnapshot.exists()) {
        return null;
      }
      return documentSnapshot.data();
    });
  }

  async getEstablishmentsForInvitedUser(userId: string) {
    const q = query(this.establishmentCollection, where(`userIds.${userId}`, '!=', null));

    return getDocs(q)
      .then(documentSnapshots => {
        return documentSnapshots.docs.map(documentSnapshot => documentSnapshot.data());
      })
      .then(establishments =>
        establishments.filter(
          establishment =>
            establishment.features.includes('USER_MANAGEMENT') &&
            this.isSubscriptionActive(establishment),
        ),
      );
  }

  getAllEstablishmentsBySlug(slugs: string[]): Observable<Establishment[]> {
    return combineLatest(
      slugs.map(slug => {
        return this.queryEstablishmentBySlug$(slug);
      }),
    ).pipe(
      map(establishments =>
        establishments.filter((establishment): establishment is Establishment => !!establishment),
      ),
    );
  }

  async update(establishmentId: string, establishment: Partial<Establishment>) {
    // TODO: refactor this logic into a reusable function (includes categories and product images too, and establishment logo)
    if (establishment.logo && establishment.logo !== this.currentEstablishment.logo) {
      establishment.logo = await this.uploadImage(establishment.logo, 'establishment-logo', 'logo');

      if (
        this.currentEstablishment.logo &&
        this.currentEstablishment.logo.includes(this.currentEstablishment.id)
      ) {
        this.fileStorage.deleteFile(this.currentEstablishment.logo);
      }
    }
    if (
      establishment.seoSocialMedia?.image &&
      establishment.seoSocialMedia?.image !== this.currentEstablishment.seoSocialMedia?.image
    ) {
      establishment.seoSocialMedia.image = await this.uploadImage(
        establishment.seoSocialMedia.image,
        'establishment-seo',
        'seo-image',
      );

      if (
        this.currentEstablishment.seoSocialMedia?.image &&
        this.currentEstablishment.seoSocialMedia?.image.includes(this.currentEstablishment.id)
      ) {
        this.fileStorage.deleteFile(this.currentEstablishment.seoSocialMedia.image);
      }
    }
    if (
      establishment.seoSocialMedia?.shortImage &&
      establishment.seoSocialMedia?.shortImage !==
        this.currentEstablishment.seoSocialMedia?.shortImage
    ) {
      establishment.seoSocialMedia.shortImage = await this.uploadImage(
        establishment.seoSocialMedia.shortImage,
        'establishment-seo',
        'seo-short-image',
      );

      if (
        this.currentEstablishment.seoSocialMedia?.shortImage &&
        this.currentEstablishment.seoSocialMedia?.shortImage.includes(this.currentEstablishment.id)
      ) {
        this.fileStorage.deleteFile(this.currentEstablishment.seoSocialMedia.shortImage);
      }
    }

    // Ensures features cannot be manipulated in code
    // TODO: this check should be better moved to a firestore.rule
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { features, ...establishmentData } = establishment;

    await updateDoc(doc(this.firestore, `establishments/${establishmentId}`), {
      ...establishmentData,
      updated: new Date(),
    });

    const updatedEstablishment = {
      ...this.currentEstablishment,
      ...establishmentData,
    };

    return updatedEstablishment;
  }

  async removeInvitedUser(userId: string) {
    const establishmentDocRef = doc(this.establishmentCollection, this.currentEstablishment.id);

    await updateDoc(establishmentDocRef, {
      [`userIds.${userId}`]: deleteField(),
      updated: new Date(),
    });
  }

  async getPrivateConfiguration() {
    const privateConfigurationDoc = await getDoc(
      doc(this.firestore, this.privateConfigurationPath),
    );
    const privateConfiguration = privateConfigurationDoc.exists()
      ? (privateConfigurationDoc.data() as PrivateConfiguration)
      : this.decoratePrivateConfiguration;

    if (!privateConfiguration.zenriseConfig) {
      privateConfiguration.zenriseConfig = { clientId: null, secretId: null };
    }

    return privateConfiguration;
  }

  savePrivateConfiguration(privateConfiguration: PrivateConfiguration) {
    return setDoc(doc(this.firestore, this.privateConfigurationPath), { ...privateConfiguration });
  }

  get decoratePrivateConfiguration(): PrivateConfiguration {
    return {
      ualaConfig: null,
      mercadopagoToken: null,
      zenriseConfig: null,
    };
  }

  isSubscriptionActive({ subscription }: Pick<Establishment, 'subscription'>) {
    // If no subscription object, this is an old establishment, let it pass and handle subscription manually offline
    if (!subscription) {
      return true;
    }

    // If the subscription is active we are done
    if (subscription.status === SubscriptionStatus.ACTIVE) {
      return true;
    }

    // If the subscription is not active we should check if it's still within a valid activation period
    const endOfCurrentPeriod = subscription.isTrial
      ? subscription.trialPeriodEndDate
      : subscription.currentPeriodEndDate;
    const endOfCurrentPeriodTime =
      typeof endOfCurrentPeriod === 'string'
        ? new Date(endOfCurrentPeriod).getTime()
        : endOfCurrentPeriod?.getTime();

    if (endOfCurrentPeriodTime && Date.now() <= endOfCurrentPeriodTime) {
      return true;
    }

    // Subscription expired
    return false;
  }

  deleteCanceledEstablishmentSubscription(establishmentId: string) {
    const deleteCanceledEstablishmentSubscriptionFn = httpsCallable<
      { establishmentId: string },
      void
    >(this.functions, 'deleteCanceledEstablishmentSubscriptionCallable');

    return deleteCanceledEstablishmentSubscriptionFn({ establishmentId });
  }

  private async uploadImage(
    imageBase64: string,
    folder: string,
    imagePrefix: string,
    fileName?: string,
  ): Promise<string> {
    const filePath = `${
      this.currentEstablishment.id
    }/${folder}/${imagePrefix}-${fileName || Date.now()}`;

    return this.fileStorage.uploadBase64File(filePath, imageBase64);
  }
}
