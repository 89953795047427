import {
  CartItem,
  CartItemOptionItems,
  getPropInSelectedLanguage,
  OptionConfiguration,
  ProductOptionItem,
  SUPPORTED_LANGUAGES,
} from '@pedix-workspace/utils';

export type GetCartItemDetailsOptions = {
  skipPresentations?: boolean;
  selectedLanguage?: SUPPORTED_LANGUAGES;
  displaySku?: boolean;
};

export type CartItemDetailsItems = {
  optionName?: string;
  items: {
    name: string;
    sku?: string;
    quantity: number;
  }[];
};

export function getCartItemDetailsText(
  cartItem: CartItem,
  { skipPresentations, selectedLanguage, displaySku }: GetCartItemDetailsOptions = {},
): string {
  const details = [];

  if (cartItem.selectedPresentation && !skipPresentations) {
    const detailItems = getCartItemDetailsItems(
      cartItem.selectedPresentation.items,
      undefined,
      selectedLanguage,
      displaySku,
    );

    details.push(getCartItemDetailsItemToText(detailItems));
  }
  if (cartItem.selectedOptions) {
    cartItem.selectedOptions.forEach(({ option, items }) => {
      const detailItems = getCartItemDetailsItems(items, option, selectedLanguage, displaySku);

      details.push(getCartItemDetailsItemToText(detailItems));
    });
  }
  return details.join('\n');
}

export function getCartItemDetailsItems(
  items: ProductOptionItem[],
  option?: OptionConfiguration,
  selectedLanguage?: SUPPORTED_LANGUAGES,
  displaySku?: boolean,
): CartItemDetailsItems {
  const optionName = option
    ? getPropInSelectedLanguage(option, 'name', selectedLanguage, true)
    : undefined;

  return {
    optionName,
    items: items.map(item => {
      return {
        name: getPropInSelectedLanguage(item, 'name', selectedLanguage, true),
        sku: displaySku && item.sku ? item.sku : undefined,
        quantity: item.quantity,
      };
    }),
  };
}

export function getCartItemDetailsItemToText(cartItemDetailsItem: CartItemDetailsItems): string {
  const itemTexts = cartItemDetailsItem.items.map(item => {
    const itemName = item.sku ? `${item.sku} | ${item.name}` : item.name;

    return (
      itemName +
      (cartItemDetailsItem.items.length > 1 || item.quantity > 1 ? ` (x${item.quantity})` : '')
    );
  });

  if (cartItemDetailsItem.optionName) {
    return `- ${cartItemDetailsItem.optionName}: ${itemTexts.join(', ')}`;
  }
  return itemTexts.join(', ');
}

export function consolidateCartItems(cartItems: CartItem[], displaySku: boolean) {
  const consolidatedCartItems = cartItems.reduce<{ [key: string]: CartItem }>(
    (consolidatedItems, cartItem) => {
      const consolidatedKey = getCartItemConsolidationKey(cartItem, displaySku);

      if (consolidatedItems[consolidatedKey]) {
        // If product has presentation with quantities, then we sum up their quantities individually
        if (consolidatedItems[consolidatedKey].product.presentations?.displayItemQuantities) {
          consolidatedItems[consolidatedKey] = mergeCartItemPresentationItems(
            consolidatedItems[consolidatedKey],
            cartItem,
          );
        } else {
          consolidatedItems[consolidatedKey].qty += cartItem.qty;
        }
      } else {
        consolidatedItems[consolidatedKey] = { ...cartItem };
      }
      return consolidatedItems;
    },
    {},
  );

  return Object.values(consolidatedCartItems);
}

export function getCartItemConsolidationKey(cartItem: CartItem, displaySku: boolean) {
  const skipPresentations = cartItem.selectedPresentation?.option.displayItemQuantities
    ? true
    : false;

  return JSON.stringify([
    cartItem.product.id,
    cartItem.product.categoryId,
    getCartItemDetailsText(cartItem, { skipPresentations, displaySku }),
    cartItem.observations,
    cartItem.selectedPresentation?.option.id || '',
  ]);
}

export function mergeCartItemPresentationItems(cartItemA: CartItem, cartItemB: CartItem) {
  const cartItemCopy = { ...cartItemA };

  cartItemCopy.selectedPresentation.items = cartItemCopy.product?.presentations.items.reduce(
    (items, currentItem) => {
      const itemOnA = cartItemA.selectedPresentation.items.find(item => item.id === currentItem.id);
      const itemOnB = cartItemB.selectedPresentation.items.find(item => item.id === currentItem.id);

      const quantity = (itemOnA?.quantity || 0) + (itemOnB?.quantity || 0);

      if (quantity > 0) {
        items.push({
          ...currentItem,
          quantity,
        });
      }
      return items;
    },
    [],
  );

  return cartItemCopy;
}

export function calculateCartItemsTotal(
  cartItems: CartItem[],
  options: { usePriceDiscount: boolean },
) {
  return cartItems.reduce(
    (total, cartItem) =>
      (total += getCartItemSubtotal(cartItem, { usePriceDiscount: options.usePriceDiscount })),
    0,
  );
}

export function getCartItemSubtotal(
  cartItem: {
    product: Pick<CartItem['product'], 'price' | 'priceDiscount'>;
    qty: CartItem['qty'];
    selectedPresentation?: CartItem['selectedPresentation'];
    selectedOptions?: CartItem['selectedOptions'];
  },
  options: { usePriceDiscount: boolean },
) {
  let price = 0;

  if (cartItem.product.price && !cartItem.selectedPresentation) {
    price =
      options.usePriceDiscount && cartItem.product.priceDiscount
        ? cartItem.product.priceDiscount
        : cartItem.product.price;
  }

  const optionItems: CartItemOptionItems[] = [];

  if (cartItem.selectedOptions) {
    optionItems.push(...cartItem.selectedOptions);
  }

  if (cartItem.selectedPresentation) {
    optionItems.push(cartItem.selectedPresentation);
  }

  optionItems.forEach(cartItemOption => {
    cartItemOption?.items?.forEach(item => {
      const itemPrice =
        options.usePriceDiscount && item.priceDiscount ? item.priceDiscount : item.price;

      price += (itemPrice || 0) * (item.quantity || 1);
    });
  });

  return (price || 0) * (cartItem.qty || 1);
}

export function getCartProductIds(cartItems: CartItem[]): string[] {
  const productIds: Set<string> = new Set();

  cartItems.forEach(cartItem => {
    productIds.add(cartItem.product.id);

    if (cartItem.selectedOptions) {
      cartItem.selectedOptions.forEach(selectedOption => {
        selectedOption.items.forEach(item => {
          if (item.isOptionItem) {
            productIds.add(item.id);
          }
        });
      });
    }
  });

  return Array.from(productIds);
}
