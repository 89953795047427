<ng-container *transloco="let t">
  <header>
    @if (establishment) {
      <div class="home-button">
        @if (displayMenuButton) {
          <pxw-icon
            class="menu-toggle"
            icon="menu"
            color="theme1-contrast"
            (click)="onClickLateralMenu($event)"
          />
        }
        <a class="ui-item-link logo-wrapper" [routerLink]="['/']">
          @if (establishmentLogo) {
            <img
              class="logo"
              [src]="establishmentLogo | imageStorage: { size: '250x250' }"
              alt="Logo {{ establishmentName }}"
            />
          } @else {
            <span class="establishment-name">{{ establishmentName }}</span>
          }
        </a>
      </div>
      <div class="admin-actions">
        <div class="ui-item-link admin-action preview">
          <pxw-button
            size="sm"
            color="theme1-contrast"
            [rounded]="true"
            [href]="establishmentUrl"
            target="_blank"
            (click)="clickOption('previewMode')"
            (mouseover)="onPreviewHover(establishmentUrl)"
          >
            {{ t('header.enterPreviewMode') }}
          </pxw-button>
          <pxw-button
            size="md"
            color="theme1-contrast"
            [rounded]="true"
            target="_blank"
            (click)="clickOption('previewMode'); onOpenPreviewer()"
            (mouseover)="onPreviewHover(establishmentUrlPreview)"
          >
            {{ t('header.enterPreviewModeLong') }}
          </pxw-button>
        </div>
        <app-user-menu
          class="ui-item-link admin-action"
          [establishment]="establishment"
          [invitedUserEstablishments]="invitedUserEstablishments"
        />
        <a
          class="ui-item-link admin-action settings"
          rel="nofollow"
          [routerLink]="['/inicio']"
          (click)="clickOption('settings')"
        >
          <pxw-icon icon="settings" color="theme1-contrast" size="md"></pxw-icon>
        </a>
        <div class="admin-action">
          <div
            class="nubo-trigger"
            id="nubo-trigger"
            [ngClass]="{ disabled: !isNuboReady }"
            (click)="clickOption('notifications')"
          >
            <pxw-icon icon="notification" color="theme1-contrast" size="md"></pxw-icon>
          </div>
        </div>
      </div>
    }
  </header>
  @if (previewerOpened) {
    <pxw-device-previewer
      [iframeUrl]="establishmentUrlPreview"
      [externalLinkUrl]="establishmentUrl"
      (closed)="onClosePreviewer()"
    ></pxw-device-previewer>
  }
</ng-container>
