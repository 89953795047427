import { Route } from '@angular/router';
import { AppAuthStateGuard } from './app-auth-state.guard';
import { AppEstablishmentGuard } from './app-establishment.guard';
import { AppTranslationsGuard } from './app-translations.guard';
import { AppLayoutComponent } from './features/main/layout/app-layout.component';
import { AdminHomeComponent } from './features/main/home/admin-home.component';
import { EstablishmentSelectorComponent } from './features/main/establishment-selector/establishment-selector.component';
import { featuresAndRolesGuard } from './app-features-and-roles.guard';

export const appAdminRoutes: Route[] = [
  {
    path: '',
    canActivate: [AppAuthStateGuard, AppTranslationsGuard],
    children: [
      {
        path: 'acceso',
        loadChildren: () => import('./features/auth/admin-auth.routes'),
        data: { preloadName: 'auth' },
      },
      {
        path: 'sucursales',
        component: EstablishmentSelectorComponent,
      },
      {
        path: '',
        canActivate: [AppEstablishmentGuard],
        component: AppLayoutComponent,
        children: [
          {
            path: 'inicio',
            component: AdminHomeComponent,
          },
          {
            path: 'catalogo',
            loadChildren: () => import('./features/catalog/admin-catalog.routes'),
            canActivate: [featuresAndRolesGuard(allowedFeatures => allowedFeatures.catalog)],
          },
          {
            path: 'analytics',
            loadChildren: () => import('./features/analytics/admin-analytics.routes'),
            canActivate: [featuresAndRolesGuard(allowedFeatures => allowedFeatures.analytics)],
          },
          {
            path: 'anuncios',
            loadChildren: () => import('./features/announcements/admin-announcements.routes'),
            canActivate: [featuresAndRolesGuard(allowedFeatures => allowedFeatures.announcements)],
          },
          {
            path: 'checkout',
            loadChildren: () => import('./features/checkout/admin-checkout.routes'),
            canActivate: [featuresAndRolesGuard(allowedFeatures => allowedFeatures.customFields)],
          },
          {
            path: 'codigo-qr',
            loadChildren: () => import('./features/qr-code/admin-qr-code.routes'),
            canActivate: [featuresAndRolesGuard(allowedFeatures => allowedFeatures.qrCode)],
          },
          {
            path: 'cupones',
            loadChildren: () => import('./features/coupon/admin-coupon.routes'),
            canActivate: [featuresAndRolesGuard(allowedFeatures => allowedFeatures.coupons)],
          },
          {
            path: 'estadisticas',
            loadChildren: () => import('./features/statistics/admin-statistics.routes'),
            canActivate: [featuresAndRolesGuard(allowedFeatures => allowedFeatures.statistics)],
          },
          {
            path: 'formas-de-envio',
            loadChildren: () => import('./features/shipping-options/admin-shipping-options.routes'),
            canActivate: [
              featuresAndRolesGuard(allowedFeatures => allowedFeatures.shippingOptions),
            ],
          },
          {
            path: 'general',
            loadChildren: () => import('./features/general/admin-general.routes'),
            canActivate: [featuresAndRolesGuard(allowedFeatures => allowedFeatures.generalInfo)],
          },
          {
            path: 'horarios',
            loadChildren: () => import('./features/working-hours/admin-working-hours.routes'),
            canActivate: [featuresAndRolesGuard(allowedFeatures => allowedFeatures.workingHours)],
          },
          {
            path: 'integraciones',
            loadChildren: () => import('./features/integrations/admin-integrations.routes'),
            canActivate: [featuresAndRolesGuard(allowedFeatures => allowedFeatures.integrations)],
          },
          {
            path: 'metodos-pago',
            loadChildren: () => import('./features/payment-methods/admin-payment-methods.routes'),
            canActivate: [featuresAndRolesGuard(allowedFeatures => allowedFeatures.paymentMethods)],
          },
          {
            path: 'pedidos',
            loadChildren: () => import('./features/orders/admin-orders.routes'),
            canActivate: [featuresAndRolesGuard(allowedFeatures => allowedFeatures.orders)],
          },
          {
            path: 'seo-social-media',
            loadChildren: () => import('./features/seo-social-media/admin-seo-social-media.routes'),
            canActivate: [featuresAndRolesGuard(allowedFeatures => allowedFeatures.seoSocialMedia)],
          },
          {
            path: 'suscripcion',
            loadChildren: () => import('./features/subscription/admin-subscription.routes'),
          },
          {
            path: 'tema',
            loadChildren: () => import('./features/theme/admin-theme.routes'),
            canActivate: [featuresAndRolesGuard(allowedFeatures => allowedFeatures.theme)],
          },
          {
            path: 'publicidad',
            loadChildren: () => import('./features/advertising/admin-advertising.routes'),
            canActivate: [featuresAndRolesGuard(allowedFeatures => allowedFeatures.ads)],
          },
          {
            path: 'usuarios',
            loadChildren: () => import('./features/users/admin-users.routes'),
            canActivate: [featuresAndRolesGuard(allowedFeatures => allowedFeatures.userManagement)],
          },
          {
            path: '**',
            redirectTo: 'inicio',
          },
        ],
      },
      {
        path: '**',
        redirectTo: 'inicio',
      },
    ],
  },
];
